<template>
  <div>
    <div class="container-buttons-report mb-1">
      <div class="align-content-end"></div>
      <div>
        <div class="noprint">
          <div class="d-flex ml-auto">
            <div
              class="d-flex"
              v-if="assessment_group_report && assessment_group_report.sections"
            >
              <div class="ml-auto mr-0 d-flex">
                <strong
                  class="mr-2 align-content-center"
                  style="font-size: 10pt"
                >
                  FORMATIVAS
                </strong>
                <b-form-checkbox
                  class="align-content-center"
                  v-model="assessment_switches_type"
                  switch
                >
                  <strong style="font-size: 10pt">SUMATIVAS</strong>
                </b-form-checkbox>
              </div>
            </div>
            <GenericButtonDownload
              v-if="
                !is_loading &&
                assessment_group_report &&
                assessment_group_report.sections &&
                assessment_group_report.sections.length
              "
              tooltip_text="Reporte XLSX"
              :click_button="DownloadExcel"
              :class="`ml-1`"
              :disabled_button="disabled_button"
              :icon_excel="true"
            ></GenericButtonDownload>
          </div>
        </div>
      </div>
    </div>

    <!-- b-table -->
    <b-skeleton-table
      v-if="is_loading"
      :rows="9"
      :columns="10"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <div class="table-wrapper">
      <b-table-simple
        bordered
        no-border-collapse
        small
        responsive
        v-if="
          !is_loading &&
          assessment_group_report &&
          assessment_group_report.sections &&
          assessment_group_report.achievement_ranges
        "
        class="table-assesment"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="text-center align-middle primary-header"
              :colspan="
                2 +
                (assessment_group_report.achievement_ranges.length + 1) *
                  2 *
                  assessment_group_report.study_units.length
              "
            >
              <div class="d-flex align-items-center justify-content-between">
                <span class="text-center mx-auto">
                  REPORTE DE ASSESSMENT GRUPAL DE LOGROS DE
                  {{
                    $getVisibleNames(
                      "teaching.ramicro",
                      false,
                      "RA Micro"
                    ).toUpperCase()
                  }}
                  DEL
                  {{
                    $getVisibleNames(
                      "mesh.egressprofile",
                      false,
                      "Perfil de egreso"
                    ).toUpperCase()
                  }}
                </span>
                <VisibleColumns
                  v-if="displayList.length > 0"
                  :id_button="`col-display-1`"
                  :tooltip_text="''"
                >
                  <template #popover-title> Visualizar columnas </template>
                  <template #popover-content>
                    <template v-if="assessment_group_report != null">
                      <div class="visible_columns">
                        <template v-for="display in displayList">
                          <div
                            :key="'index' + display.index + 'id' + display.id"
                          >
                            <div class="noprint">
                              <b-form-checkbox
                                :id="`column-check-${display.index}`"
                                name="checkbox-1"
                                v-model="display.visible"
                                :disabled="
                                  displayList.filter(
                                    (a) => a.visible && a.id != display.id
                                  ).length < 1 && display.visible
                                "
                              >
                                {{ display.title }}
                                {{ display.order ? display.order : "" }}
                              </b-form-checkbox>
                            </div>
                          </div>
                        </template>
                      </div>
                    </template>
                  </template>
                </VisibleColumns>
              </div>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th
              class="text-center align-middle secondary-header"
              rowspan="3"
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.egressprofilematter',
                        true,
                        'Asignaturas'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
            >
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                ).toUpperCase()
              }}
            </b-th>
            <b-th
              class="text-center align-middle secondary-header"
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'teaching.section',
                        true,
                        'secciones'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
              rowspan="3"
            >
              {{
                $getVisibleNames(
                  "teaching.section",
                  true,
                  "secciones"
                ).toUpperCase()
              }}
            </b-th>
            <template
              v-for="assessment_label in assessment_group_report.study_units"
            >
              <b-th
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          assessment_label.id
                        } ${assessment_label.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                "
                :colspan="
                  (assessment_group_report.achievement_ranges.length + 1) * 2
                "
                v-b-tooltip.v-secondary.noninteractive="
                  `${assessment_label.full_sentence}`
                "
                class="text-center align-middle secondary-header cursor-pointer"
                :key="`header-assessment-${assessment_label.id}`"
                :id="`header-assessment-top-${assessment_label.id}`"
              >
                {{ assessment_label.short_name.toUpperCase() }}
              </b-th>
            </template>
          </b-tr>
          <b-tr>
            <template v-for="study_unit in assessment_group_report.study_units">
              <template
                v-for="assessment_label in assessment_group_report.achievement_ranges"
              >
                <b-th
                  v-if="
                    displayList.some(
                      (x) =>
                        $equals(
                          `${x.id} ${x.title}`,
                          `${
                            study_unit.id
                          } ${study_unit.short_name.toUpperCase()}`
                        ) && x.visible
                    )
                  "
                  colspan="2"
                  v-b-tooltip.v-secondary.noninteractive="
                    `${assessment_label.start_achievement} ${
                      assessment_label.include_start_achievement ? '≤' : '<'
                    } X ${
                      assessment_label.include_finish_achievement ? '≤' : '<'
                    } ${assessment_label.finish_achievement}`
                  "
                  class="text-center align-middle secondary-header cursor-pointer"
                  :style="`background-color:${assessment_label.color} !important`"
                  :key="`header-assessment-${assessment_label.id}-${study_unit.id}`"
                  :id="`header-assessment-top-${assessment_label.id}-${study_unit.id}`"
                >
                  {{ assessment_label.name.toUpperCase() }}
                </b-th>
              </template>
              <b-th
                :key="`header-assessment--${study_unit.id}`"
                colspan="2"
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                "
                class="text-center align-middle secondary-header"
                >SIN REGISTRO</b-th
              >
            </template>
          </b-tr>
          <b-tr>
            <template v-for="study_unit in assessment_group_report.study_units">
              <template
                v-for="assessment_label in assessment_group_report.achievement_ranges"
              >
                <b-th
                  v-if="
                    displayList.some(
                      (x) =>
                        $equals(
                          `${x.id} ${x.title}`,
                          `${
                            study_unit.id
                          } ${study_unit.short_name.toUpperCase()}`
                        ) && x.visible
                    )
                  "
                  class="text-center align-middle secondary-header cursor-pointer"
                  v-b-tooltip.v-secondary.noninteractive="`Cantidad/Porcentaje`"
                  :key="`header-assessment-3-cant${assessment_label.id}-${study_unit.id}`"
                  :id="`header-assessment-top-3-cant${assessment_label.id}-${study_unit.id}`"
                >
                  Cant
                </b-th>
                <b-th
                  v-if="
                    displayList.some(
                      (x) =>
                        $equals(
                          `${x.id} ${x.title}`,
                          `${
                            study_unit.id
                          } ${study_unit.short_name.toUpperCase()}`
                        ) && x.visible
                    )
                  "
                  class="text-center align-middle secondary-header cursor-pointer"
                  v-b-tooltip.v-secondary.noninteractive="`Porcentaje`"
                  :key="`header-assessment-3-%${assessment_label.id}-${study_unit.id}`"
                  :id="`header-assessment-top-3-%${assessment_label.id}-${study_unit.id}`"
                >
                  %
                </b-th>
              </template>
              <b-th
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                "
                class="text-center align-middle secondary-header cursor-pointer"
                v-b-tooltip.v-secondary.noninteractive="`Cantidad/Porcentaje`"
                :key="`header-assessment-4-cant${study_unit.id}`"
                :id="`header-assessment-top-4-cant${study_unit.id}`"
              >
                Cant
              </b-th>
              <b-th
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                "
                class="text-center align-middle secondary-header cursor-pointer"
                v-b-tooltip.v-secondary.noninteractive="`Porcentaje`"
                :key="`header-assessment-4-%${study_unit.id}`"
                :id="`header-assessment-top-4-%${study_unit.id}`"
              >
                %
              </b-th>
            </template>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(assessment, index) in assessment_group_report.sections"
            :key="`row-${assessment.id}-${index}`"
          >
            <!-- Asignaturas -->
            <b-td
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'mesh.egressprofilematter',
                        true,
                        'Asignaturas'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
              class="text-left"
            >
              {{ egress_profile_matter.matter_name }}
            </b-td>
            <!-- Sección -->
            <b-td
              v-if="
                displayList.some(
                  (x) =>
                    $equals(
                      x.title,
                      $getVisibleNames(
                        'teaching.section',
                        true,
                        'secciones'
                      ).toUpperCase()
                    ) && x.visible
                )
              "
              class="text-left"
            >
              {{ assessment.section_name }}
            </b-td>
            <template v-for="study_unit in assessment.study_units">
              <template
                v-for="(
                  assessment_label, index
                ) in study_unit.achievement_ranges"
              >
                <b-td
                  :key="`study_unit:${study_unit.id}-achievement_ranges${index}`"
                  v-if="
                    displayList.some(
                      (x) =>
                        $equals(
                          `${x.id} ${x.title}`,
                          `${
                            study_unit.id
                          } ${study_unit.short_name.toUpperCase()}`
                        ) && x.visible
                    )
                  "
                  class="text-center"
                >
                  <div v-if="assessment_switches_type">
                    {{ assessment_label.range_count_summative | Round }}
                  </div>
                  <div v-else>
                    {{ assessment_label.range_count_formative | Round }}
                  </div>
                </b-td>
                <b-td
                  :key="`study_unit:${study_unit.id}-achievement_ranges${index}-%`"
                  v-if="
                    displayList.some(
                      (x) =>
                        $equals(
                          `${x.id} ${x.title}`,
                          `${
                            study_unit.id
                          } ${study_unit.short_name.toUpperCase()}`
                        ) && x.visible
                    )
                  "
                  class="text-center"
                >
                  <div v-if="assessment_switches_type">
                    {{ assessment_label.range_percentage_summative | Round }}%
                  </div>
                  <div v-else>
                    {{ assessment_label.range_percentage_formative | Round }}%
                  </div>
                </b-td>
              </template>
              <!-- SIN REGISTRO -->

              <b-td
                :key="`study_unit:${study_unit.id}-Cant`"
                class="text-center"
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                "
              >
                <div v-if="assessment_switches_type">
                  {{ study_unit.unregistered_count_summative | Round }}
                </div>
                <div v-else>
                  {{ study_unit.unregistered_count_formative | Round }}
                </div>
              </b-td>
              <b-td
                :key="`study_unit:${study_unit.id}-%`"
                class="text-center"
                v-if="
                  displayList.some(
                    (x) =>
                      $equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                "
              >
                <div v-if="assessment_switches_type">
                  {{ study_unit.unregistered_percentage_summative | Round }}%
                </div>
                <div v-else>
                  {{ study_unit.unregistered_percentage_formative | Round }}%
                </div>
              </b-td>
            </template>
          </b-tr>
          <b-tr v-if="assessment_group_report.sections.length == 0">
            <b-td :colspan="displayList.length">
              No hay datos que mostrar
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple
        bordered
        no-border-collapse
        small
        responsive
        v-if="
          assessment_group_report &&
          assessment_group_report.sections &&
          assessment_group_report.sections.length > 0 &&
          assessment_group_report.study_units &&
          assessment_group_report.study_units.length > 0
        "
        class="table-assesment-range-print"
      >
        <b-thead>
          <b-th
            class="text-center align-middle primary-header"
            colspan="2"
          ></b-th>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="study_unit in assessment_group_report.study_units"
            :key="`print-table-${study_unit.id}`"
          >
            <b-td class="text-left">{{ study_unit.short_name }} </b-td>
            <b-td class="text-left">{{ study_unit.full_sentence }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <!-- Grafico -->
      <GenericGraphic
        v-if="sectionOptions.length > 0"
        :labels="chartLabels"
        :datasets="chartDatasets"
        :legend_labels="legendLabels"
        :title_graphic="`REPORTE DE ASSESSMENT GRUPAL DE LOGROS DE ${$getVisibleNames(
          'teaching.ramicro',
          true,
          'RA MICRO'
        ).toUpperCase()} POR ${$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        ).toUpperCase()}`"
        :total_students="totalStudents"
        :label_y_axis="`N° de Estudiantes`"
        :title_table_circular="`Assessment`"
      >
        <template #selector>
          <div class="d-flex">
            <!-- Selector de sección -->
            <b-form-select
              id="sectionSelector"
              v-model="selected_section"
              :options="sectionOptions"
              @change="updateSection"
              class="select-section-type mr-3"
            ></b-form-select>
            <b-form-select
              v-if="selected_section != null"
              id="assessmentSelector"
              v-model="selected_assessment"
              :options="assessmentOptions"
              @change="updateAssessment"
              class="select-graphic-type"
            ></b-form-select>
          </div>
        </template>
      </GenericGraphic>
    </div>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentGroupStudyUnitTable",
  components: {
    VisibleColumns: () =>
      import("@/components/reusable/Buttons/VisibleColumns"),
    GenericButtonDownload: () =>
      import("@/components/reusable/Buttons/GenericButtonDownload"),
    GenericGraphic: () => import("@/components/reusable/GenericGraphic"),
  },
  props: {
    egress_profile: {
      type: Object,
      required: true,
    },
    egress_profile_matter: {
      type: Object,
      required: true,
    },
    achievement_range_group: {
      type: [Number, null],
      required: false,
      default: null,
    },
    cohorts_: {
      type: Array,
      required: true,
    },
    cycles_: {
      type: Array,
    },
    periods_: {
      type: Array,
    },
    sections_: {
      type: Array,
    },
    title_graphic: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      is_loading: false, // Variable para mostrar "skeletons"
      disabled_button: false,
      assessment_group_report: [],
      displayList: [],
      assessment_switches_type: true,
      selected_assessment: "compare_all", // Variable para guardar assessment del selector.
      selected_section: null,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
    sectionOptions() {
      if (!this.assessment_group_report?.sections) return [];

      const options = [];

      options.push({
        value: null,
        text: "Seleccione una Sección",
      });

      this.assessment_group_report.sections.forEach((x) => {
        options.push({
          value: x.section_id,
          text: x.section_name,
        });
      });

      return options;
    },
    assessmentOptions() {
      if (!this.assessment_group_report?.sections || !this.selected_section)
        return [];

      // Sección seleccionada
      const selectedSection = this.assessment_group_report.sections.find(
        (section) => section.section_id == this.selected_section
      );

      if (!selectedSection) return [];

      // Generar los study_units segun la sección seleccionada
      const options = selectedSection.study_units.map((studyUnit) => ({
        value: studyUnit.id,
        text: `${studyUnit.short_name}`.trim(),
      }));

      // Añadir opción "Comparar todos"
      options.push({
        value: "compare_all",
        text: `Ver todos los ${
          selectedSection.study_units[0]?.short_name_assessment_type || ""
        }`,
      });

      return options;
    },
    totalStudents() {
      if (!this.assessment_group_report?.sections || !this.selected_section)
        return 0;

      const selectedSection = this.assessment_group_report.sections.find(
        (x) => x.section_id == this.selected_section
      );

      return selectedSection?.evaluatee_count || 0;
    },
    // Etiquetas del gráfico (eje X)
    chartLabels() {
      if (!this.assessment_group_report?.sections || !this.selected_section)
        return [];

      const selectedSection = this.assessment_group_report.sections.find(
        (section) => section.section_id == this.selected_section
      );

      if (!selectedSection) return [];

      if (this.selected_assessment == "compare_all") {
        return selectedSection.study_units.map(
          (studyUnit) => `${studyUnit.short_name}`
        );
      }

      // Para la opción individual se mostra el short_name
      const selected = selectedSection.study_units.find(
        (x) => x.id == this.selected_assessment
      );
      return selected ? [`${selected.short_name}`] : [];
    },

    // Datos del gráfico (eje Y)
    chartDatasets() {
      if (
        !this.assessment_group_report?.achievement_ranges ||
        !this.selected_section ||
        !this.selected_assessment
      ) {
        return [];
      }

      // Se busca la sección seleccionada
      const selectedSection = this.assessment_group_report.sections.find(
        (section) => section.section_id == this.selected_section
      );

      if (!selectedSection) return [];

      const datasets = [];

      // Si se selecciona "Comparar todos"
      if (this.selected_assessment == "compare_all") {
        // Crear un dataset por cada achievement_range
        this.assessment_group_report.achievement_ranges.forEach((range) => {
          const data = selectedSection.study_units.map((studyUnit) => {
            const matchingRange = studyUnit.achievement_ranges.find(
              (x) => x.id == range.id
            );
            return matchingRange
              ? this.assessment_switches_type
                ? matchingRange.range_count_summative
                : matchingRange.range_count_formative
              : 0;
          });

          datasets.push({
            label: range.name, // Nombre del rango
            data: data, // Datos para el gráfico
            backgroundColor: range.color, // Color del achievement_range
          });
        });

        // Agregar dataset para los sin Registro
        const unregisteredData = selectedSection.study_units.map((studyUnit) =>
          this.assessment_switches_type
            ? studyUnit.unregistered_count_summative
            : studyUnit.unregistered_count_formative
        );

        datasets.push({
          label: "Sin Registro", // Etiqueta para los sin Registro
          data: unregisteredData, // Datos de sin Registro
          backgroundColor: "#cccccc", // Color gris para diferenciar
        });
      } else {
        // Si se selecciona una opción individual
        const selected = selectedSection.study_units.find(
          (x) => x.id == this.selected_assessment
        );

        if (selected) {
          // Crear un dataset por cada achievement_range para el study_unit seleccionado
          this.assessment_group_report.achievement_ranges.forEach((range) => {
            const matchingRange = selected.achievement_ranges.find(
              (x) => x.id == range.id
            );

            datasets.push({
              label: range.name,
              data: [
                matchingRange
                  ? this.assessment_switches_type
                    ? matchingRange.range_count_summative
                    : matchingRange.range_count_formative
                  : 0,
              ],
              backgroundColor: range.color,
            });
          });

          // Agregar dataset para los sin Registro
          datasets.push({
            label: "Sin Registro",
            data: [
              this.assessment_switches_type
                ? selected.unregistered_count_summative
                : selected.unregistered_count_formative,
            ],
            backgroundColor: "#cccccc",
          });
        }
      }

      return datasets;
    },
    legendLabels() {
      if (
        this.assessment_group_report &&
        this.assessment_group_report.achievement_ranges
      ) {
        // Devuelve los nombres de los rangos
        const labels = this.assessment_group_report.achievement_ranges.map(
          (x) => ({
            label: x.name,
            color: x.color,
          })
        );

        // Añadir la etiqueta para los sin Registro
        labels.push({
          label: "Sin Registro", // Etiqueta para los sin Registro
          color: "#cccccc", // Color gris para los sin Registro
        });

        return labels;
      }
      return [];
    },
  },
  methods: {
    updateSection(selectedId) {
      this.selected_section = selectedId;
      this.selected_assessment = "compare_all";
    },
    updateAssessment(selectedId) {
      this.selected_assessment = selectedId;
    },
    completeDisplayList(response) {
      this.displayList = [
        {
          index: 0,
          id: this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          ).toUpperCase(),
          title: this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          ).toUpperCase(),
          visible: true,
        },
        {
          index: 1,
          id: this.$getVisibleNames(
            "teaching.section",
            true,
            "secciones"
          ).toUpperCase(),
          title: this.$getVisibleNames(
            "teaching.section",
            true,
            "secciones"
          ).toUpperCase(),
          visible: true,
        },
      ];
      let display_index = this.displayList.length;
      if (response.study_units && response.study_units.length > 0)
        response.study_units.forEach((element) => {
          this.displayList.push({
            index: display_index,
            id: element.id,
            title: `${element.short_name.toUpperCase()}`,
            order: "",
            visible: true,
          });
          display_index += 1;
        });
    },
    getAssessmentRange() {
      this.is_loading = true;
      this.displayList = [];
      //   period: this.periods_[0].id,
      this.$restful
        .Post(`/assessment2/group_study_unit_report/`, {
          egress_profile_matter: this.egress_profile_matter.id,
          cohort: this.cohorts_[0].cohort,
          achievement_range_group: this.achievement_range_group,
          sections: this.sections_.map((x) => x.id),
        })
        .then((response) => {
          this.assessment_group_report = response;
          this.completeDisplayList(response);
          this.$swal.close();
          this.is_loading = false;
        })
        .catch(() => {
          this.is_loading = false;
          this.$swal.close();
          this.$swal.fire(
            "Error",
            "Hubo un problema al generar el reporte.",
            "error"
          );
        });
    },
    DownloadExcel() {
      this.disabled_button = true;
      //   period: this.periods_[0].id,
      this.$restful
        .Post(`assessment2/excel_report_group_study_unit_report/`, {
          egress_profile_matter: this.egress_profile_matter.id,
          cohort: this.cohorts_[0].cohort,
          achievement_range_group: this.achievement_range_group,
          sections: this.sections_.map((x) => x.id),
        })
        .then((response) => {
          if (response && response.file_url) {
            window.open(response.file_url, "_blank");
            this.disabled_button = false;
          }
        });
    },
  },
  created() {
    this.getAssessmentRange();
  },
};
</script>

<style scoped>
.select-section-type {
  width: auto;
}
.container-buttons-report {
  display: flex;
  justify-content: right;
}
.primary-header {
  background-color: var(--kl-menu-color) !important;
  color: white;
}
.secondary-header {
  background: #d0cece !important;
}
.col-display-1,
.col-display-2 {
  min-width: 200px;
  margin-left: 2em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.col-display-1:hover,
.col-display-2:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
  transition: all 0.3s;
}
.truncated-text {
  white-space: nowrap; /* Evita el salto de línea */
  overflow: hidden; /* Oculta el contenido que se desborda */
  text-overflow: ellipsis; /* Agrega "..." al final */
  width: 100%; /* Asegura que no sobrepase el contenedor */
}
.visible_columns {
  max-height: 300px;
  overflow: auto;
}
.cursor-pointer {
  cursor: help;
}

@media (max-width: 768px) {
  .table-wrapper {
    overflow-x: auto;
  }

  .table-assesment-range-table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse; /* Evita que las celdas se sobrepongan */
  }
  .table-assesment {
    width: 100%;
    min-width: 800px;
    border-collapse: collapse; /* Evita que las celdas se sobrepongan */
  }
}
/* .table-assesment-print-table {
  display: none;
} */

@media print {
  .table-assesment-print-table {
    display: block;
  }
  .badge {
    border: none;
  }
}
</style>

