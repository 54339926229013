var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-buttons-report mb-1"},[_c('div',{staticClass:"align-content-end"}),_c('div',[_c('div',{staticClass:"noprint"},[_c('div',{staticClass:"d-flex ml-auto"},[(_vm.assessment_group_report && _vm.assessment_group_report.sections)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto mr-0 d-flex"},[_c('strong',{staticClass:"mr-2 align-content-center",staticStyle:{"font-size":"10pt"}},[_vm._v(" FORMATIVAS ")]),_c('b-form-checkbox',{staticClass:"align-content-center",attrs:{"switch":""},model:{value:(_vm.assessment_switches_type),callback:function ($$v) {_vm.assessment_switches_type=$$v},expression:"assessment_switches_type"}},[_c('strong',{staticStyle:{"font-size":"10pt"}},[_vm._v("SUMATIVAS")])])],1)]):_vm._e(),(
              !_vm.is_loading &&
              _vm.assessment_group_report &&
              _vm.assessment_group_report.sections &&
              _vm.assessment_group_report.sections.length
            )?_c('GenericButtonDownload',{class:`ml-1`,attrs:{"tooltip_text":"Reporte XLSX","click_button":_vm.DownloadExcel,"disabled_button":_vm.disabled_button,"icon_excel":true}}):_vm._e()],1)])])]),(_vm.is_loading)?_c('b-skeleton-table',{attrs:{"rows":9,"columns":10,"table-props":{ bordered: true, striped: true }}}):_vm._e(),_c('div',{staticClass:"table-wrapper"},[(
        !_vm.is_loading &&
        _vm.assessment_group_report &&
        _vm.assessment_group_report.sections &&
        _vm.assessment_group_report.achievement_ranges
      )?_c('b-table-simple',{staticClass:"table-assesment",attrs:{"bordered":"","no-border-collapse":"","small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-center align-middle primary-header",attrs:{"colspan":2 +
              (_vm.assessment_group_report.achievement_ranges.length + 1) *
                2 *
                _vm.assessment_group_report.study_units.length}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',{staticClass:"text-center mx-auto"},[_vm._v(" REPORTE DE ASSESSMENT GRUPAL DE LOGROS DE "+_vm._s(_vm.$getVisibleNames( "teaching.ramicro", false, "RA Micro" ).toUpperCase())+" DEL "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil de egreso" ).toUpperCase())+" ")]),(_vm.displayList.length > 0)?_c('VisibleColumns',{attrs:{"id_button":`col-display-1`,"tooltip_text":''},scopedSlots:_vm._u([{key:"popover-title",fn:function(){return [_vm._v(" Visualizar columnas ")]},proxy:true},{key:"popover-content",fn:function(){return [(_vm.assessment_group_report != null)?[_c('div',{staticClass:"visible_columns"},[_vm._l((_vm.displayList),function(display){return [_c('div',{key:'index' + display.index + 'id' + display.id},[_c('div',{staticClass:"noprint"},[_c('b-form-checkbox',{attrs:{"id":`column-check-${display.index}`,"name":"checkbox-1","disabled":_vm.displayList.filter(
                                  (a) => a.visible && a.id != display.id
                                ).length < 1 && display.visible},model:{value:(display.visible),callback:function ($$v) {_vm.$set(display, "visible", $$v)},expression:"display.visible"}},[_vm._v(" "+_vm._s(display.title)+" "+_vm._s(display.order ? display.order : "")+" ")])],1)])]})],2)]:_vm._e()]},proxy:true}],null,false,2747130127)}):_vm._e()],1)])],1),_c('b-tr',[(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'mesh.egressprofilematter',
                      true,
                      'Asignaturas'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header",attrs:{"rowspan":"3"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" ).toUpperCase())+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'teaching.section',
                      true,
                      'secciones'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-th',{staticClass:"text-center align-middle secondary-header",attrs:{"rowspan":"3"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.section", true, "secciones" ).toUpperCase())+" ")]):_vm._e(),_vm._l((_vm.assessment_group_report.study_units),function(assessment_label){return [(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        assessment_label.id
                      } ${assessment_label.short_name.toUpperCase()}`
                    ) && x.visible
                )
              )?_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `${assessment_label.full_sentence}`
              ),expression:"\n                `${assessment_label.full_sentence}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],key:`header-assessment-${assessment_label.id}`,staticClass:"text-center align-middle secondary-header cursor-pointer",attrs:{"colspan":(_vm.assessment_group_report.achievement_ranges.length + 1) * 2,"id":`header-assessment-top-${assessment_label.id}`}},[_vm._v(" "+_vm._s(assessment_label.short_name.toUpperCase())+" ")]):_vm._e()]})],2),_c('b-tr',[_vm._l((_vm.assessment_group_report.study_units),function(study_unit){return [_vm._l((_vm.assessment_group_report.achievement_ranges),function(assessment_label){return [(
                  _vm.displayList.some(
                    (x) =>
                      _vm.$equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                )?_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `${assessment_label.start_achievement} ${
                    assessment_label.include_start_achievement ? '≤' : '<'
                  } X ${
                    assessment_label.include_finish_achievement ? '≤' : '<'
                  } ${assessment_label.finish_achievement}`
                ),expression:"\n                  `${assessment_label.start_achievement} ${\n                    assessment_label.include_start_achievement ? '≤' : '<'\n                  } X ${\n                    assessment_label.include_finish_achievement ? '≤' : '<'\n                  } ${assessment_label.finish_achievement}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],key:`header-assessment-${assessment_label.id}-${study_unit.id}`,staticClass:"text-center align-middle secondary-header cursor-pointer",style:(`background-color:${assessment_label.color} !important`),attrs:{"colspan":"2","id":`header-assessment-top-${assessment_label.id}-${study_unit.id}`}},[_vm._v(" "+_vm._s(assessment_label.name.toUpperCase())+" ")]):_vm._e()]}),(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        study_unit.id
                      } ${study_unit.short_name.toUpperCase()}`
                    ) && x.visible
                )
              )?_c('b-th',{key:`header-assessment--${study_unit.id}`,staticClass:"text-center align-middle secondary-header",attrs:{"colspan":"2"}},[_vm._v("SIN REGISTRO")]):_vm._e()]})],2),_c('b-tr',[_vm._l((_vm.assessment_group_report.study_units),function(study_unit){return [_vm._l((_vm.assessment_group_report.achievement_ranges),function(assessment_label){return [(
                  _vm.displayList.some(
                    (x) =>
                      _vm.$equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                )?_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Cantidad/Porcentaje`),expression:"`Cantidad/Porcentaje`",modifiers:{"v-secondary":true,"noninteractive":true}}],key:`header-assessment-3-cant${assessment_label.id}-${study_unit.id}`,staticClass:"text-center align-middle secondary-header cursor-pointer",attrs:{"id":`header-assessment-top-3-cant${assessment_label.id}-${study_unit.id}`}},[_vm._v(" Cant ")]):_vm._e(),(
                  _vm.displayList.some(
                    (x) =>
                      _vm.$equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                )?_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Porcentaje`),expression:"`Porcentaje`",modifiers:{"v-secondary":true,"noninteractive":true}}],key:`header-assessment-3-%${assessment_label.id}-${study_unit.id}`,staticClass:"text-center align-middle secondary-header cursor-pointer",attrs:{"id":`header-assessment-top-3-%${assessment_label.id}-${study_unit.id}`}},[_vm._v(" % ")]):_vm._e()]}),(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        study_unit.id
                      } ${study_unit.short_name.toUpperCase()}`
                    ) && x.visible
                )
              )?_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Cantidad/Porcentaje`),expression:"`Cantidad/Porcentaje`",modifiers:{"v-secondary":true,"noninteractive":true}}],key:`header-assessment-4-cant${study_unit.id}`,staticClass:"text-center align-middle secondary-header cursor-pointer",attrs:{"id":`header-assessment-top-4-cant${study_unit.id}`}},[_vm._v(" Cant ")]):_vm._e(),(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        study_unit.id
                      } ${study_unit.short_name.toUpperCase()}`
                    ) && x.visible
                )
              )?_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Porcentaje`),expression:"`Porcentaje`",modifiers:{"v-secondary":true,"noninteractive":true}}],key:`header-assessment-4-%${study_unit.id}`,staticClass:"text-center align-middle secondary-header cursor-pointer",attrs:{"id":`header-assessment-top-4-%${study_unit.id}`}},[_vm._v(" % ")]):_vm._e()]})],2)],1),_c('b-tbody',[_vm._l((_vm.assessment_group_report.sections),function(assessment,index){return _c('b-tr',{key:`row-${assessment.id}-${index}`},[(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'mesh.egressprofilematter',
                      true,
                      'Asignaturas'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.egress_profile_matter.matter_name)+" ")]):_vm._e(),(
              _vm.displayList.some(
                (x) =>
                  _vm.$equals(
                    x.title,
                    _vm.$getVisibleNames(
                      'teaching.section',
                      true,
                      'secciones'
                    ).toUpperCase()
                  ) && x.visible
              )
            )?_c('b-td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(assessment.section_name)+" ")]):_vm._e(),_vm._l((assessment.study_units),function(study_unit){return [_vm._l((study_unit.achievement_ranges),function(assessment_label,index){return [(
                  _vm.displayList.some(
                    (x) =>
                      _vm.$equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                )?_c('b-td',{key:`study_unit:${study_unit.id}-achievement_ranges${index}`,staticClass:"text-center"},[(_vm.assessment_switches_type)?_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(assessment_label.range_count_summative))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(assessment_label.range_count_formative))+" ")])]):_vm._e(),(
                  _vm.displayList.some(
                    (x) =>
                      _vm.$equals(
                        `${x.id} ${x.title}`,
                        `${
                          study_unit.id
                        } ${study_unit.short_name.toUpperCase()}`
                      ) && x.visible
                  )
                )?_c('b-td',{key:`study_unit:${study_unit.id}-achievement_ranges${index}-%`,staticClass:"text-center"},[(_vm.assessment_switches_type)?_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(assessment_label.range_percentage_summative))+"% ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(assessment_label.range_percentage_formative))+"% ")])]):_vm._e()]}),(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        study_unit.id
                      } ${study_unit.short_name.toUpperCase()}`
                    ) && x.visible
                )
              )?_c('b-td',{key:`study_unit:${study_unit.id}-Cant`,staticClass:"text-center"},[(_vm.assessment_switches_type)?_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(study_unit.unregistered_count_summative))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(study_unit.unregistered_count_formative))+" ")])]):_vm._e(),(
                _vm.displayList.some(
                  (x) =>
                    _vm.$equals(
                      `${x.id} ${x.title}`,
                      `${
                        study_unit.id
                      } ${study_unit.short_name.toUpperCase()}`
                    ) && x.visible
                )
              )?_c('b-td',{key:`study_unit:${study_unit.id}-%`,staticClass:"text-center"},[(_vm.assessment_switches_type)?_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(study_unit.unregistered_percentage_summative))+"% ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("Round")(study_unit.unregistered_percentage_formative))+"% ")])]):_vm._e()]})],2)}),(_vm.assessment_group_report.sections.length == 0)?_c('b-tr',[_c('b-td',{attrs:{"colspan":_vm.displayList.length}},[_vm._v(" No hay datos que mostrar ")])],1):_vm._e()],2)],1):_vm._e(),(
        _vm.assessment_group_report &&
        _vm.assessment_group_report.sections &&
        _vm.assessment_group_report.sections.length > 0 &&
        _vm.assessment_group_report.study_units &&
        _vm.assessment_group_report.study_units.length > 0
      )?_c('b-table-simple',{staticClass:"table-assesment-range-print",attrs:{"bordered":"","no-border-collapse":"","small":"","responsive":""}},[_c('b-thead',[_c('b-th',{staticClass:"text-center align-middle primary-header",attrs:{"colspan":"2"}})],1),_c('b-tbody',_vm._l((_vm.assessment_group_report.study_units),function(study_unit){return _c('b-tr',{key:`print-table-${study_unit.id}`},[_c('b-td',{staticClass:"text-left"},[_vm._v(_vm._s(study_unit.short_name)+" ")]),_c('b-td',{staticClass:"text-left"},[_vm._v(_vm._s(study_unit.full_sentence))])],1)}),1)],1):_vm._e(),(_vm.sectionOptions.length > 0)?_c('GenericGraphic',{attrs:{"labels":_vm.chartLabels,"datasets":_vm.chartDatasets,"legend_labels":_vm.legendLabels,"title_graphic":`REPORTE DE ASSESSMENT GRUPAL DE LOGROS DE ${_vm.$getVisibleNames(
        'teaching.ramicro',
        true,
        'RA MICRO'
      ).toUpperCase()} POR ${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      ).toUpperCase()}`,"total_students":_vm.totalStudents,"label_y_axis":`N° de Estudiantes`,"title_table_circular":`Assessment`},scopedSlots:_vm._u([{key:"selector",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('b-form-select',{staticClass:"select-section-type mr-3",attrs:{"id":"sectionSelector","options":_vm.sectionOptions},on:{"change":_vm.updateSection},model:{value:(_vm.selected_section),callback:function ($$v) {_vm.selected_section=$$v},expression:"selected_section"}}),(_vm.selected_section != null)?_c('b-form-select',{staticClass:"select-graphic-type",attrs:{"id":"assessmentSelector","options":_vm.assessmentOptions},on:{"change":_vm.updateAssessment},model:{value:(_vm.selected_assessment),callback:function ($$v) {_vm.selected_assessment=$$v},expression:"selected_assessment"}}):_vm._e()],1)]},proxy:true}],null,false,1542472876)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }